import React from "react"
import { graphql, Link } from "gatsby"
import "bulma/css/bulma.css"
import "../styles/stronaKategoriaGlowna.scss"
import _navigation from "../components/_navigation"
import Footer from "../components/footer"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import SideNavigation from "../components/sideNavigation"
import ArrowBack from "@material-ui/icons/ArrowBack"
import ReactPlayer from "react-player"
import Home from "@material-ui/icons/Home"
import { Helmet } from "react-helmet"

export const query = graphql`
  query($slug: String!) {
    contentfulStronaKategoriaGlowna(slug: { eq: $slug }) {
      tytul
      childContentfulStronaKategoriaGlownaOpisRichTextNode {
        json
      }
      slug
    }
    allContentfulNawigacjaMenu {
      edges {
        node {
          kategoriaGlowna
          podkategorie
          slug
        }
      }
    }
  }
`
const options = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: node => {
      const opis = node.data.target.fields.description && node.data.target.fields.description.pl
      const url = node.data.target.fields.file && node.data.target.fields.file.pl.url
      const width = node.data.target.fields.file && node.data.target.fields.file.pl.details.image.width
      const height = node.data.target.fields.file && node.data.target.fields.file.pl.details.image.height
      return node.data.target.fields ? (
        <React.Fragment>
          <br clear="left" />
          <img
            style={{ display: "block", margin: "0 auto", maxWidth: "450px", maxHeight: "450px" }}
            className="img-fluid"
            vspace="20"
            title={opis}
            height={height < 100 ? height * 1.3 : height}
            width={width < 300 ? width * 1.3 : width}
            alt={opis}
            src={url}
          />
        </React.Fragment>
      ) : (
        <div></div>
      )
    },
  },
  renderText: text => text.split("\n").flatMap((text, i) => [i > 0 && <br />, text]),
  renderMark: {
    [MARKS.CODE]: embedded => <ReactPlayer style={{ display: "block", margin: "0 auto" }} url={embedded[1]} />,
  },
}

const StronaKategoriaGlowna = props => {
  const katGlownaSlug = props.data.contentfulStronaKategoriaGlowna?.slug
  const podkategorie =
    props.data.allContentfulNawigacjaMenu?.edges &&
    props.data.allContentfulNawigacjaMenu.edges.filter(kat => kat.node.slug === katGlownaSlug)[0] &&
    props.data.allContentfulNawigacjaMenu.edges.filter(kat => kat.node.slug === katGlownaSlug)[0].node.podkategorie
  const tytul = props.data.contentfulStronaKategoriaGlowna.tytul
  const body =
    props.data.contentfulStronaKategoriaGlowna.childContentfulStronaKategoriaGlownaOpisRichTextNode &&
    props.data.contentfulStronaKategoriaGlowna.childContentfulStronaKategoriaGlownaOpisRichTextNode.json &&
    documentToReactComponents(
      props.data.contentfulStronaKategoriaGlowna.childContentfulStronaKategoriaGlownaOpisRichTextNode.json,
      options
    )
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`${tytul} Laser-Medica`}</title>
        <meta
          name="description"
          content="Dermatologia Laserowa i Estetyczna, Depilacja Laserowa. Zabiegi dermatochirurgiczne oraz ginekologia estetyczna. Szeroka oferta zabiegów dermatologicznych."
        />
      </Helmet>
      <_navigation />
      <section className="hero hero-small-background">
        <div className="hero-body has-text-centered">
          <div className="container">
            <h1 className="kat-glowna-tytul">{tytul}</h1>
          </div>
        </div>
      </section>
      <div className="columns nav-bread-container">
        <nav style={{ marginBottom: "2rem" }} className="breadcrumb column is-offset-1 is-10" aria-label="breadcrumbs">
          <ul style={{ fontSize: "12px" }}>
            <li key="glowna">
              <Link className="ikona-home" to="/">
                <Home />
              </Link>
            </li>
            <li className="is-active" aria-current="page">
              <Link to={`/${katGlownaSlug}`}>{tytul}</Link>
            </li>
          </ul>
        </nav>
      </div>
      <div className="columns main-wrapper-kategoria-sidenav-i-body">
        {podkategorie && (
          <div className="sidenav-parent column is-desktop is-offset-1-tablet is-3-desktop is-4-tablet is-4-widescreen is-3-fullhd">
            <SideNavigation podkategorie={podkategorie} />
          </div>
        )}
        <section
          className={`section column ${podkategorie ? `is-6` : `is-6 is-offset-3`} strona-kategoria-glowna-container`}
        >
          <div className="is-medium container-opis-body">{body}</div>
        </section>
      </div>
      <div className="button-wrapper-wstecz">
        <Link to={"/"}>
          <button className="button button-wstecz-promocje is-primary is-light">
            <ArrowBack /> Wstecz
          </button>
        </Link>
      </div>
      <Footer />
    </div>
  )
}
export default StronaKategoriaGlowna
